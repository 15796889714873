<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">OFFICIAL RECEIPT MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table dense
                    :headers="headers"
                    :items="transmital_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td class="text-center">
              {{ item.collector_name }}
            </td>
            <td class="text-center">
              {{ item.stab_no }}
            </td>
            <td class="text-center">
              {{ item.start+' - '+item.end }}
            </td>
            <td class="text-center">
              {{ item.address }}
            </td>
            <td class="text-center">
              {{ item.date_release}}
            </td>
            <td class="text-center">
              {{ item.last_used}}
            </td>
            <td class="text-center">
              {{ item.history}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      this.init()
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'COLLECTOR NAME', value: 'date_of_deposit', sortable: false},
          {text: 'STAB #', value: 'date_of_deposit', sortable: false},
          {text: 'OR SERIES', value: 'time_of_deposit', sortable: false},
          {text: 'OR ADDRESS', value: 'amount', sortable: false},
          {text: 'DATE  RELEASED', value: 'bank_code_name', sortable: false},
          {text: 'LAST USED', value: 'bank_code_name', sortable: false},
          {text: 'HISTORY', value: 'bank_code_name', sortable: false},],
        re_assigned_dialog: false,
        key: 0,

        new_collector_id: 0,
        last_used: 0,
        new_date_release: '',

        saving: false,
        alert: false,
        alert_message: '',

        data: [],
        collectors: [],
        collector_id: 0,
        transmital_ors_id: 0,
        transmital_no: 0,
        stab_no: 0,
        or_series: '',
        or_address: '',
        date_release: '',

        transmital_data: [],
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('staff', ['find_staff_with_position_active']),
      ...mapActions('transmital_or', ['get_or_monitoring']),
      init() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.get_or_monitoring(data)
          .then(response => {
            this.transmital_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

        this.collectors = []
        this.collector_id = 0
        this.transmital_ors_id = 0
        this.transmital_no = 0
        this.stab_no = 0
        this.or_series = ''
        this.or_address = ''
        this.date_release = ''

        this.new_collector_id = 0
        this.last_used = 0
        this.new_date_release = ''
      },
      activerow(value) {
        this.date_release = value.date_release
        this.collector_id = value.collector_id
        this.last_used = value.last_used
        this.transmital_ors_id = value.id
        this.re_assigned_dialog = true
        this.selectedDepositId = value.id;


        this.transmital_no = value.transmital_no
        this.stab_no = value.stab_no
        this.or_series = value.start + '-' + value.end
        this.or_address = value.address
      },
    }
  }
</script>
